<template>
  <div class="Bulk6">
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
    <div v-if="annexData.length>0">
      <el-table
          :data="annexData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="value"
            label="项目"
            width="110"
        >
          <template slot-scope="scope">
            <img @click="downloadPDF(scope.row.value)"
                 :src="scope.row.type == 'xls' || scope.row.type == 'xlsx'? require('@/assets/excel.png') : scope.row.type == 'doc' || scope.row.type == 'docx' ?
                 require('@/assets/word.png') : require('@/assets/pdf.png')"
                 width="40"
                 height="40" alt=""/>
          </template>
        </el-table-column>
        <el-table-column
            prop="text"
            label="内容"
        >
          <template slot-scope="scope">
            <p @click="downloadPDF(scope.row.value)">{{ scope.row.text }}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk6Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk6',
  data() {
    return {
      dataText: "",
      tableData: undefined,
      annexData: []
    }
  },
  mounted() {
    document.title = '招标信息池-敬业招标详情'

    getBulk6Details({id: this.$route.params.id}).then(res => {
      this.tableData = [
        {text: '项目名称', value: res[0].planName},
        {text: '招标方式', value: res[0].biddingMode},
        {text: '报价方式', value: res[0].quotationMode},
        {text: '开标室', value: res[0].openingRoomName},
        {text: '开始时间', value: res[0].openingBeginTime},
        {text: '结束时间', value: res[0].openingEndTime},
        {text: '报价截止时间', value: res[0].quotationDeadLine},
        {text: '附件数量', value: res[0].jyList.length},
      ];
      for (var i = 0; i < res[0].jyList.length; i++) {
        var array = {}
        array.text = res[0].jyList[i].fileName
        array.value = res[0].jyList[i].filePath
        let strArray = res[0].jyList[i].filePath.split(".");
        array.type = strArray[3]
        this.annexData.push(array)
      }
    })
  },
  methods: {
    downloadPDF(row) {
      window.open(row)
    },
  }
}
</script>


<style scoped>
.Bulk6 {
  background: white;
}

.detail {
  text-align: left;
  font-size: 17px;
  padding-bottom: 0.6em;
  padding-left: 0.6em;
}

.bdbottom {
  height: 32px;
  align-items: center;
  border-bottom: 1px solid #eee;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #676a6c;
  padding-top: 0.6em;
  padding-left: 0.6em;
}

.vcenter {
  display: flex;
  align-items: center;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #676a6c;
  padding-top: 0.6em;
  padding-left: 0.6em;
}

.last {
  display: flex;
  align-items: center;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #676a6c;
  padding-top: 0.6em;
  padding-left: 0.6em;
  padding-bottom: 0.6em;
}

</style>
